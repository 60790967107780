import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import api from "./api";
import "./plugins";
import "@/assets/font2/iconfont.css";

Vue.config.productionTip = false;
Vue.prototype.$api = api;
new Vue({
  store,
  router,
  render: (h) => h(App),
}).$mount("#app");
