<template>
  <transition name="toast-anime">
    <div class="toast" v-if="flag">
      <div class="toast-container">
        <span :class="['iconfont', type]"></span>
        <p>
          {{ message }}
        </p>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "toast",
  data() {
    return {
      flag: false,
      type: "icon-caozuochenggong",
      message: "操作成功",
    };
  },
  methods: {
    success(msg, data) {
      this.flag = true;
      this.type = "icon-caozuochenggong";
      this.message = msg || "操作成功";
      setTimeout(() => (this.flag = false), data || 3000);
    },
    error(msg, data) {
      this.flag = true;
      this.type = "icon-caozuoshibai";
      this.message = msg || "操作失败";
      setTimeout(() => (this.flag = false), data || 3000);
    },
  },
};
</script>

<style lang="scss" scoped>
.toast-anime-enter-active {
  animation: bounce;
  animation-duration: 2s;
}

.toast-anime-leave-active {
  animation: fadeOut;
  animation-duration: 1s;
}

.toast {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;

  .toast-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 120px;
    height: 120px;
    background: rgba(0, 0, 0, 0.6);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .iconfont {
      color: white;
      font-size: 30px;
      margin-bottom: 15px;
    }

    & p {
      padding: 0 10px;
      color: white;
      font-size: 14px;
    }
  }
}
</style>
