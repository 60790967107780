import Vue from "vue";
import VueRouter from "vue-router";
import config from "@/config";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/home",
  },
  {
    name: "home",
    path: "/home",
    component: () => import("@/views/home"),
    meta: {
      title: "首页",
      keepAlive: true,
      showTabBar: true,
    },
  },
  {
    name: "message",
    path: "/message",
    component: () => import("@/views/message"),
    meta: {
      title: "消息",
      showTabBar: true,
    },
  },
  {
    name: "category",
    path: "/category",
    component: () => import("@/views/category"),
    meta: {
      title: "促销",
      showTabBar: true,
    },
  },
  {
    name: "cart",
    path: "/cart",
    component: () => import("@/views/cart"),
    meta: {
      title: "客服",
      showTabBar: true,
    },
  },
  {
    name: "profile",
    path: "/profile",
    component: () => import("@/views/profile"),
    meta: {
      title: "我的",
      showTabBar: true,
    },
  },
  {
    name: "detail",
    path: "/detail/:goodsId",
    component: () => import("@/views/detail"),
    meta: {
      title: "商品详情",
    },
  },
  {
    name: "login",
    path: "/login",
    component: () => import("@/views/login"),
    meta: {
      title: "登录",
    },
  },
  {
    name: "register",
    path: "/register",
    component: () => import("@/views/register"),
    meta: {
      title: "注册",
    },
  },
  {
    name: "categoryDetail",
    path: "/cdetail/:id/",
    component: () => import("@/views/categoryDetail"),
    meta: {
      title: "促销详情",
      showTabBar: true,
    },
  },
  {
    name: "goodsOrder",
    path: "/goods-order/:type",
    component: () => import("@/views/goodsOrder"),
    meta: {
      title: "订单详情",
    },
  },
  {
    name: "withdrawal",
    path: "/withdrawal",
    component: () => import("@/views/withdrawal"),
    meta: {
      title: "申请提现",
    },
  },
  {
    name: "setting",
    path: "/setting",
    component: () => import("@/views/setting"),
    meta: {
      title: "个人设置",
    },
  },
  {
    name: "binding",
    path: "/binding/:type",
    component: () => import("@/views/binding"),
    meta: {
      title: "绑定",
    },
  },
  {
    name: "withdraw-list",
    path: "/withdraw-list",
    component: () => import("@/views/withdraw-list"),
    meta: {
      title: "提现记录",
    },
  },
  {
    name: "pay-list",
    path: "/pay-list",
    component: () => import("@/views/pay-list"),
    meta: {
      title: "充值记录",
    },
  },
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  window.document.title = to.meta.title;
  var link =
    document.querySelector("link[rel*='icon']") ||
    document.createElement("link");
  link.type = "image/x-icon";
  link.rel = "shortcut icon";
  link.href = config.logo;
  document.getElementsByTagName("head")[0].appendChild(link);
  next();
});

export default router;
