import Vue from "vue";

class CreateComponent {
  static create(component) {
    const MsgConstructor = Vue.extend(component);
    const sunComponent = new MsgConstructor();
    sunComponent.$mount();
    document.body.appendChild(sunComponent.$el);
    return sunComponent;
  }
}

export default CreateComponent;
