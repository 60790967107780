import Vue from "vue";

import {
  Pagination,
  Button,
  Swipe,
  SwipeItem,
  Lazyload,
  Sticky,
  Loading,
  List,
  Form,
  Field,
  Uploader,
  RadioGroup,
  Radio,
  Toast,
  Tag,
  Skeleton,
  Cell,
  CellGroup,
  GoodsAction,
  GoodsActionIcon,
  GoodsActionButton,
  Sku,
  Checkbox,
  CheckboxGroup,
  SubmitBar,
  Badge,
  Empty,
  Image,
  ContactCard,
  Tab,
  Tabs,
  Icon,
  NoticeBar,
  CountDown,
} from "vant";

[
  CountDown,
  NoticeBar,
  Pagination,
  Button,
  Swipe,
  SwipeItem,
  Lazyload,
  Sticky,
  Loading,
  List,
  Form,
  Field,
  Uploader,
  RadioGroup,
  Radio,
  Toast,
  Tag,
  Skeleton,
  Cell,
  CellGroup,
  GoodsAction,
  GoodsActionIcon,
  GoodsActionButton,
  Sku,
  Checkbox,
  CheckboxGroup,
  SubmitBar,
  Badge,
  Empty,
  Image,
  ContactCard,
  Tab,
  Tabs,
  Icon,
].forEach((cpn) => Vue.use(cpn));
