import request from "@/plugins/axios";

export default {
  /**
   * 查询轮播数据
   * */
  queryBanner(params) {
    return request({
      url: "/home/banner",
      data: params,
      method: "get",
      mock: false,
      loading: false,
    });
  },
  /**
   * 查询热门
   * */
  queryRecommend(params) {
    return request({
      url: "/home/recommend",
      data: params,
      method: "get",
      mock: false,
      loading: false,
    });
  },
  /**
   * 根据类型和页码数查询商品数据
   * */
  queryGoods(params) {
    return request({
      url: "/api/goods/list",
      data: params,
      method: "get",
      mock: false,
      loading: true,
    });
  },
};
