import Vue from "vue";
import Vuex from "vuex";
import VuexPersistedstate from "vuex-persistedstate";
import api from "@/api";
import router from "@/router";
import { toast } from "@/plugins/sunUI";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    userInfo: {},
    token: "",
    // url:  process.env.NODE_ENV === "development"?"https://159.138.142.247:4001/images":"/images",
    url:  process.env.NODE_ENV === "development"? "https://159.138.142.247:4001/images": location.protocol+"//" + location.host+"/images",
    // url:  process.env.NODE_ENV === "development"? "https://159.138.142.247:4001/images": location.protocol+"//" + location.host+"/images",
    kefu: "https://www.baidu.com",
    notice: "1",
  },
  getters: {},
  mutations: {
    saveUserInfo: (state, payload) => (state.userInfo = payload),
    saveToken: (state, payload) => (state.token = payload),
    saveUrl: (state, payload) => (state.url = payload),
    saveKefu: (state, payload) => (state.kefu = payload),
    saveNotice: (state, payload) => (state.notice = payload),
    toKefu: (state) => {
      window.location.href = state.kefu;
    },
  },
  actions: {
    loginAction: async (ctx, payload) => {
      try {
        const { user, token } = await api.login(payload);
        ctx.commit("saveUserInfo", user);
        ctx.commit("saveToken", token);
        // 跳转profile页面
        toast.success("登录成功");
        setTimeout(async () => {
          await router.replace({ name: "profile" });
        }, 2000);
      } catch (error) {
        console.log(error);
      }
    },
    urlAction: async (ctx) => {
      // const url = await api.queryUrl();
      const kefu = await api.baseMsg({ title: "kefu" });
      const notice = await api.baseMsg({ title: "notice" });
      // ctx.commit("saveUrl", url);
      ctx.commit("saveKefu", kefu);
      ctx.commit("saveNotice", notice);
    },
  },
  modules: {},
  plugins: [
    VuexPersistedstate({
      storage: window.localStorage, // 存储到localStorage
      key: "store", // 存储的key
      paths: ["userInfo", "token", "url", "kefu", "notice"], // 存储state
    }),
  ],
});
