import HomeApi from "./home";
import CategoryApi from "./category";
import DetailApi from "./detail";
import LoginApi from "./login";
import BaseApi from "./base";

export default {
  ...HomeApi,
  ...CategoryApi,
  ...DetailApi,
  ...LoginApi,
  ...BaseApi,
};
