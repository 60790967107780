/**
 * 环境配置封装
 */
import logo from "@/assets/logo.png";

const env = process.env.NODE_ENV || "production";
const Envconfig = {
  development: {
    baseApi: "http://159.138.142.247:4001",
    mockApi: "",
  },
  test: {
    baseApi: "",
  },
  production: {
    baseApi: location.protocol+"//" + location.host, //https://xxyp1009.com:4000  https://youpin9988.com:4000  //https://you5009.com:4000  //xxyp7001.com
    mockApi: "",
  },
};

export default {
  env,
  mock: false,
  ...Envconfig[env],
  name: "",
  left: true,
  logo,
};
