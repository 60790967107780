import request from "@/plugins/axios";

export default {
  /**
   * 查询Url
   * */
  queryUrl() {
    return request({
      url: "/api/util/url",
      method: "get",
      mock: false,
      loading: false,
    });
  },
  /**
   * 个人信息
   */
  userInfo() {
    return request({
      url: "/api/user/info",
      method: "post",
      mock: false,
    });
  },
  /**
   * profile/order/goodslist
   */
  profile(data) {
    return request({
      url: "/api/user/profile",
      method: "get",
      data,
      mock: false,
    });
  },
  /**
   * profile
   */
  queryDetailOrderList(data) {
    return request({
      url: "/api/goods/order/goodslist",
      method: "get",
      data,
      mock: false,
    });
  },
  /**
   * 类型列表
   */
  typeList() {
    return request({
      url: "/api/system/type/list",
      method: "get",
      mock: false,
    });
  },
  /**
   * 分类列表
   */
  classifyList() {
    return request({
      url: "/api/system/classify/list",
      method: "get",
      mock: false,
    });
  },
  /**
   * 修改会员信息
   */
  modifyUserInfo(params) {
    return request({
      url: "/api/user/modify",
      data: params,
      method: "post",
      mock: false,
    });
  },
  useWithdraw(params) {
    return request({
      url: "/api/user/withdraw",
      data: params,
      method: "post",
      mock: false,
    });
  },
  useWithdrawList(params) {
    return request({
      url: "/api/user/d/list",
      data: params,
      method: "get",
      mock: false,
    });
  },
  usePayList(params) {
    return request({
      url: "/api/user/p/list",
      data: params,
      method: "get",
      mock: false,
    });
  },
  /**
   * 类型修改
   */
  baseMsg(params) {
    return request({
      url: "/api/system/url",
      method: "get",
      data: params,
      mock: false,
    });
  },
};
