/**
 * axios二次封装
 */
import axios from "axios";
import config from "@/config";
import store from "@/store";
import router from "@/router";
import { loading, toast } from "@/plugins/sunUI";

const TOKEN_INVALID = "Token invalid";
const NETWORK_ERROR = "Network error";

// 创建axios实例对象，添加全局配置
const service = axios.create({
  baseURL: config.baseApi,
  timeout: 8000,
});

// 请求拦截
service.interceptors.request.use((req) => {
  //配置Loading
  if (req.loading) {
    loading.show();
  }
  //请求头中携带Token
  const headers = req.headers;
  const token = store.state.token || "";
  if (!headers.Authorization) headers.Authorization = "Bearer " + token;
  return req;
});

// 响应拦截
service.interceptors.response.use((res) => {
  //关闭Loading
  loading.close();
  //处理响应结果
  const { code, data, msg } = res.data;

  if (code === 200) {
    return data;
  } else if (code === 500001) {
    toast.error("Token 认证失败");
    setTimeout(async () => {
      await router.push("/login");
    }, 1500);
    return Promise.reject(TOKEN_INVALID);
  } else {
    toast.error(msg || NETWORK_ERROR);
    return Promise.reject(msg || NETWORK_ERROR);
  }
});

/**
 * 请求核心函数
 * @param {*} options 请求配置
 */
function request(options) {
  options.method = options.method || "get";
  if (options.method.toLowerCase() === "get") {
    options.params = options.data;
  }
  let isMock = config.mock;
  if (typeof options.mock != "undefined") {
    isMock = options.mock;
  }
  if (config.env === "production") {
    service.defaults.baseURL = config.baseApi;
  } else {
    service.defaults.baseURL = isMock ? config.mockApi : config.baseApi;
  }

  return service(options);
}

export default request;
