<template>
  <div id="app">
    <div
      :style="{
        marginBottom: $route.meta.showTabBar ? 55 + 'px' : 0,
      }"
    >
      <keep-alive>
        <router-view v-if="$route.meta.keepAlive"></router-view>
      </keep-alive>
      <router-view v-if="!$route.meta.keepAlive"></router-view>
    </div>
    <tabbar
      v-show="$route.meta.showTabBar"
      ref="tabbarRef"
      :tabs="tabs"
      active-color="#e73720"
      default-color="#a1a5aa"
    >
    </tabbar>
  </div>
</template>

<script>
import { tabbar } from "@/plugins/sunUI";
import { mapActions } from "vuex";

export default {
  name: "App",
  data() {
    return {
      tabs: [
        {
          name: "home",
          icon: "icon-iconfonthome0",
          title: "首页",
          color: "#a1a5aa",
          acolor:"#e73720"
        },
        {
          name: "message",
          icon: "icon-xiaoxi",
          title: "消息",
          color: "#a1a5aa",
          acolor:"#e73720"
        },
        {
          name: "category",
          icon: "icon-shangcheng",
          title: "比购",
          color: "#a1a5aa",
          acolor:"#e73720"
        },
        {
          name: "cart",
          icon: "icon-kefu",
          title: "服务台",
          color: "#a1a5aa",
          acolor:"#e73720"
        },
        {
          name: "profile",
          title: "我的",
          icon: "icon-user",
          color: "#a1a5aa",
          acolor:"#e73720"
        },
      ],
      tabbarHeight: "",
    };
  },
  components: {
    tabbar,
  },
  mounted() {
    this.urlAction();

  },
  methods: {
    ...mapActions(["urlAction"]),
  },
};
</script>

<style lang="scss" scoped>
@import "@/style/base.css";
@import "@/assets/font/iconfont.css";
</style>
