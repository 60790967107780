import request from "@/plugins/axios";

export default {
  /**
   * 查询分类
   * */
  queryCategory(params) {
    return request({
      url: "/category",
      data: params,
      method: "get",
      mock: false,
      loading: false,
    });
  },
  /**
   * 查询分类列表
   * */
  querySubCategory(maitKey) {
    return request({
      url: `/category/${maitKey}`,
      method: "get",
      mock: false,
      loading: false,
    });
  },
};
