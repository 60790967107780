import request from "@/plugins/axios";

export default {
  /**
   * 注册
   * */
  register(params) {
    return request({
      url: `/api/user/register`,
      method: "post",
      data: params,
      mock: false,
      loading: true,
    });
  },
  /**
   * 登录
   * */
  login(params) {
    return request({
      url: `/api/user/login`,
      method: "post",
      data: params,
      mock: false,
      loading: true,
    });
  },
};
