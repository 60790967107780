<template>
  <div class="tab-bar-inner">
    <slot name="src"></slot>
    <slot name="icon"></slot>
    <slot name="title"></slot>
  </div>
</template>

<script>
export default {
  name: "tab-bar-inner",

  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="scss" scoped></style>
