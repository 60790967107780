<template>
  <div class="loading animate__animated animate__pulse" v-if="flag">
    <div class="loading-container">
      <img src="./img/loading.gif" alt="文件路径错误"/>
    </div>
  </div>
</template>

<script>
export default {
  name: "loading",
  data() {
    return {
      flag: false,
    };
  },
  methods: {
    show() {
      this.flag = true;
    },
    close() {
      this.flag = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.loading {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;

  .loading-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 120px;
    height: 120px;
    background: rgba(0, 0, 0, 0.6);
    border-radius: 5px;

    & img {
      width: 120px;
      height: 120px;
    }
  }
}
</style>
