<template>
  <div class="tab-bar">
    <div class="tab-bar-container">
      <tabbar-item
        class="tab-bar-item"
        v-for="item in tabs"
        :key="item.name"
        :style="{
          color: item.name === $route.name ? item.acolor : item.color,
        }"
        @click.native="switchRoute(item.name)"
      >
        <span slot="icon" :class="['iconfont', item.icon]"></span>
        <p slot="title">{{ item.title }}</p>
      </tabbar-item>
    </div>
  </div>
</template>

<script>
import tabbarItem from "./tab-bar-inner";

export default {
  name: "tab-bar",
  props: {
    tabs: {
      type: Array,
      default: () => [],
    },
    defaultColor: {
      type: String,
      default: "#808080",
    },
    activeColor: {
      type: String,
      default: "red",
    },
  },
  data() {
    return {};
  },
  components: {
    tabbarItem,
  },
  methods: {
    switchRoute(name) {
      if (this.$route.name !== name) {
        this.$router.replace({ name });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.tab-bar {
  .tab-bar-container {
    background: #fff;
    height: 55px;
    border-top: 1px solid #ededf0;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;

    .tab-bar-item {
      flex: 1;
      text-align: center;
      font-size: 13px;

      .iconfont {
        font-size: 26px;
      }
    }
  }
}
</style>
