import request from "@/plugins/axios";

export default {
  /**
   * 发起拼团/order/create/list
   * */
  initiateAGroup(params) {
    return request({
      url: `api/goods/order/create`,
      method: "post",
      data: params,
      mock: false,
      loading: false,
    });
  },
  /**
   * 发起拼团/order/create/list
   * */
  endGroup(params) {
    return request({
      url: `api/goods/order/end`,
      method: "post",
      data: params,
      mock: false,
      loading: false,
    });
  },
  /**
   * 发起列表
   * */
  listGroup(params) {
    return request({
      url: `api/goods/order/create/list`,
      method: "post",
      data: params,
      mock: false,
      loading: false,
    });
  },
};
